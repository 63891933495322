(function() {

    "use strict";

    var app = {};

    app.swipe = (function() {

        // Enable text selection
        //delete Hammer.defaults.cssProps.userSelect;

        var element = document.querySelector('body'),
            container = document.querySelector('.container');

        try {
            var hammer = new Hammer.Manager(element),
                swipe = new Hammer.Swipe(),
                tap = new Hammer.Tap();

            // Add swipe & tap recognition

            hammer.add(swipe);
            hammer.add(tap);
        } catch (error) {
            console.log("Looks like Hammer library is missing!");
        }

        function getBackLinkPosition() {
            var backBtn = document.querySelector('header .back-btn');
            if (backBtn) {
                return backBtn.getBoundingClientRect();
            } else {
                return false;
            }
        }

        function backBtnClicked(clickPos) {
            var btnPos = getBackLinkPosition();
            if (btnPos) {
                return (btnPos.bottom > clickPos.y && btnPos.left < clickPos.x);
            }
        }

        function chartClicked(clickPos) {
            var chart = document.querySelector('.benefit-stats'),
                offset = parseInt($('.chart').css('margin-top'), 10),
                clickedOutside = true;

            if (chart) {
                var chartArea = chart.getBoundingClientRect();
                if (clickPos.x > chartArea.left && clickPos.x < chartArea.left + chartArea.width && clickPos.y > (chartArea.top - offset) && clickPos.y < chartArea.top + chartArea.height) {
                    clickedOutside = false;
                }
            }

            return clickedOutside;
        }

        // Determine swipe direction and retrieve target url
        function swipeTarget(direction, container) {
            var containerWidth = container.offsetWidth;

            if (direction.type === "tap") {
                if (!backBtnClicked(direction.center) && chartClicked(direction.center)) {
                    return getUrl(container, (direction.center.x > (containerWidth / 2)));
                }
            } else {
                return getUrl(container, (direction.type === "swipeleft"));
            }
        }

        function getUrl(container, next) {
            return next ? container.getAttribute('data-next') : container.getAttribute('data-prev');
        }

        function toggleVisibility(container, className, show) {

            if (container) {
                if (show) {
                    window.addEventListener('load', function() {
                        container.classList.add(className);
                    });
                } else {
                    container.classList.remove(className);
                }
            }
        }

        // Handle swipe event
        function swipeHandler(ev) {
            var targetUrl = swipeTarget(ev, container);

            if (targetUrl) {
                toggleVisibility(container, 'visible');
                setTimeout(function() {
                    window.location.href = targetUrl;
                }, 500);
            }
        }

        // Attach listener
        function swipeTo() {
            $(document).ready(function() {
                // hammer.on("swipeleft swiperight tap", swipeHandler);
            });
        }

        function init() {
            toggleVisibility(container, 'visible', true);

            if (typeof Hammer !== 'undefined') {
                swipeTo();
            }
        }

        return {
            init: init
        };

    })();

    app.chartNetwork = (function(){

        var state = 0;
        $('.select-all-pharmacies').click(function(){
            if(state === 0){
                $('.benefit-content input').prop('checked', true);
                state = 1;
            } else {
                $('.benefit-content input').prop('checked', false);
                state = 0;
            }
        });

        var qLabels = ["Q1", "Q2", "Q3", "Q4"];

        function numberWithSpaces(x, chartType, sum, offerType) {

            if(offerType === 'time'){
                return x.toFixed(2) + ' dni';
            }

            if(chartType){
                return parseInt(x * 100) + ' %';
            } else {

                if(sum === true){
                    return x.toLocaleString('pl-PL') + ' zł';
                } else {

                    if(x > 0 && x < 0.5){
                        return Math.ceil(x).toLocaleString('pl-PL') + ' zł';
                    } else {
                        return Math.round(x).toLocaleString('pl-PL') + ' zł';
                    }
                }
            }
        }

        function numberWithSpacesTime(x) {
            if(x == 0){
                return null;
            } else {
                return x.toFixed(2) + ' dni';
            }
        }

        function setLegend(columns){
            columns.forEach(function(item){
                var legendItems = $('.chart-header-legend');
                legendItems.find('.' + item[0].toLowerCase()).addClass('active');
            });
        }

        function setLegendDescription(dataBaseInformations, allDescriptions, legendType, patternTab, realTab){


            var chartLegend = $('<ul>');

            if(legendType === 'service'){

                for(var x = 0; x < realTab.length; x++) {

                    for(var it in allDescriptions){
                        if (allDescriptions.hasOwnProperty(it)) {

                            if (realTab[x] === allDescriptions[it].name) {

                                var invisibleService = '';
                                if (allDescriptions[it].description === '') {
                                    invisibleService = 'hide';
                                }

                                var liService = $('<li>');
                                $('<a href="#" class="' + invisibleService + '">').appendTo(liService);
                                $('<p>' + allDescriptions[it].description + '</p>').appendTo(liService);

                                liService.appendTo(chartLegend);
                            }
                        }
                    }
                }

                chartLegend.appendTo('.c3-chart-legend.all');

            } else {


                var patternTabFiltered = patternTab.filter(function(item) {
                    return (item !== null);
                });


                var tempTab = [];

                for (var i = 0; i < dataBaseInformations.length; i++) {

                    tempTab.push(patternTabFiltered[i]);

                    for (var item in allDescriptions){
                        if (allDescriptions.hasOwnProperty(item)) {


                            if(dataBaseInformations[i] === allDescriptions[item].name){

                                var invisible = '';
                                if(allDescriptions[item].description === ''){
                                    invisible = 'hide';
                                }

                                var li = $('<li>');
                                $('<a href="#" class="'+invisible+'">').appendTo(li);
                                $('<p>' + allDescriptions[item].description + '</p>').appendTo(li);
                                li.appendTo(chartLegend);
                            }
                        }
                    }
                }
            }

            if (legendType === 'fus') {
                chartLegend.appendTo('.c3-chart-legend.fus');
            }

            if (legendType === 'rest') {
                chartLegend.appendTo('.c3-chart-legend.rest');
            }

            if (legendType === 'all') {
                chartLegend.appendTo('.c3-chart-legend.all');
            }
        }

        function drawChart(columns, category, chartType, offerType){

            var chartId = '#chart-base-offer';

            if(offerType === "offer-fus"){
                chartId = '#chart-base-offer-fus';

                if(columns.length === 0){
                    $('#chart-base-offer-fus').closest('.chart-fus').hide();
                    $('.chart-rest').css('width', '100%');
                }
            }

            if(offerType === "offer-rest"){
                chartId = '#chart-base-offer-rest';
            }

            var groups, percentage = {};
            var barWidth = 45;

            if(chartType){
                groups = [];
                percentage = {
                    format: d3.format('.0%'),
                    values: [0.25,0.5,0.75,1]
                };
                barWidth = 30;
            } else {
                groups = [
                    [qLabels[0], qLabels[1], qLabels[2], qLabels[3]]
                ];
            }

            if(offerType === 'time'){
                groups = [];
                barWidth = 65;
            }

            function showLastLabel(chartType) {

                if(!chartType){
                    var shown = chart.data.shown().map(function(item){ return item.id; }); // get visible ids: ['data1', 'data2', ...]
                    var last = (shown[shown.length - 1]);

/////mod
                    if(offerType !== 'offer-fus' && offerType !== 'offer-rest' && offerType !== 'time'){
                       // d3.select('.c3-chart-texts').selectAll('.c3-target').style('display', 'none'); // hide all
                       // d3.select('.c3-chart-texts').selectAll('.c3-target-' + last).style('display', 'block'); // show last
                    }

                    if(offerType === 'offer-fus'){
                        // d3.select('#chart-base-offer-fus .c3-chart-texts').selectAll('.c3-target').style('display', 'none'); // hide all
                        // d3.select('#chart-base-offer-fus .c3-chart-texts').selectAll('.c3-target-' + last).style('display', 'block'); // show last
                    }

                    if(offerType === 'offer-rest'){
                        // d3.select('#chart-base-offer-rest .c3-chart-texts').selectAll('.c3-target').style('display', 'none'); // hide all
                        // d3.select('#chart-base-offer-rest .c3-chart-texts').selectAll('.c3-target-' + last).style('display', 'block'); // show last
                    }
                }
            }

            var paddingConfigY, paddingConfig;

            if(offerType === 'service') {
                paddingConfigY = {top:15, bottom:0};
                paddingConfig =  {bottom: 28, top: 0};
            } else {
                paddingConfigY = {top: 100, bottom:0};
                paddingConfig =  {bottom: 10, top: 60};
            }

            if(offerType === 'offer-fus' || offerType === 'offer-rest') {
                paddingConfigY = {top: 100, bottom:0};
                paddingConfig =  {bottom: 40, top: 60};
            }

            var totals = [], totalsTime = [];

            var chart = c3.generate({
                bindto: chartId,
                onrendered: function(){
                    //var totals = [];
                    d3.selectAll(".c3-axis.c3-axis-y .tick, .c3-axis.c3-axis-y .tick text").style("display", "none");

                    function parseSVG(s) {
                        var div = document.createElementNS('http://www.w3.org/1999/xhtml', 'div');
                        div.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg">' + s + '</svg>';
                        var frag = document.createDocumentFragment();
                        while (div.firstChild.firstChild)
                            frag.appendChild(div.firstChild.firstChild);
                        return frag;
                    }

                    if($('#chart-base-offer[data-type-offer="offer"]').length > 0) {

                        var barHeight = [];
                        var barValues = [];

                        $('#chart-base-offer[data-type-offer="offer"] .c3-chart-bar').each(function () {
                            $(this).find('.c3-bars .c3-bar').each(function (index) {
                                barHeight[index] = (barHeight[index] || 0) + parseInt($(this).height());
                            });
                        });

                        var chartHeight = $('.c3-event-rects').height();
                        var offset = 10;

                        $('#chart-base-offer[data-type-offer="offer"] .c3-chart-text').each(function () {
                            $(this).find('.c3-texts .c3-text').each(function (index) {
                                $(this).attr('y', chartHeight - barHeight[index] - offset);
                                var valueTab = $(this).text().split(' ')[0].replace(/\s/g, '');
                                barValues[index] = (parseInt(barValues[index]) || 0) + parseInt(valueTab);
                            });
                            offset += 15;
                        });

                        $('#chart-base-offer[data-type-offer="offer"] .c3-chart-text').each(function (index) {
                            if (index === 0) {
                                $(this).find('.c3-texts .c3-text').each(function (index) {

                                    var offsetY = $(this).attr('y');
                                    var offsetX = $(this).attr('x');

                                    document.querySelector('#chart-base-offer[data-type-offer="offer"] svg').appendChild(parseSVG(
                                        '<text class="c3-text-' + index + '" font-size="14" font-weight="bold" text-anchor="middle" style="stroke: none; fill: rgb(0,0,0); fill-opacity: 1;" x="' + (parseInt(offsetX) + 60) + '" y="' + (parseInt(offsetY)) + '">' + barValues[index].toLocaleString('pl-PL') + ' zł</text>'
                                    ));

                                });
                            }
                        });
                    }



                    if($('#chart-base-offer-fus').length > 0) {

                        var barHeightFus = [];
                        var barValuesFus = [];

                        $('#chart-base-offer-fus .c3-chart-bar').each(function () {
                            $(this).find('.c3-bars .c3-bar').each(function (index) {
                                barHeightFus[index] = (barHeightFus[index] || 0) + parseInt($(this).height());
                            });
                        });

                        var chartHeightFus = $('.c3-event-rects').height();
                        var offsetFus = 10;

                        $('#chart-base-offer-fus .c3-chart-text').each(function () {
                            $(this).find('.c3-texts .c3-text').each(function (index) {
                                $(this).attr('y', chartHeightFus - barHeightFus[index] - offsetFus);
                                var valueTabFus = $(this).text().split(' ')[0].replace(/\s/g, '');
                                barValuesFus[index] = (parseInt(barValuesFus[index]) || 0) + parseInt(valueTabFus);
                            });
                            offsetFus += 15;
                        });

                        $('#chart-base-offer-fus .c3-chart-text').each(function (index) {
                            if (index === 0) {
                                $(this).find('.c3-texts .c3-text').each(function (index) {

                                    var offsetY = $(this).attr('y');
                                    var offsetX = $(this).attr('x');

                                    document.querySelector('#chart-base-offer-fus svg').appendChild(parseSVG(
                                        '<text class="c3-text-' + index + '" font-size="14" font-weight="bold" text-anchor="middle" style="stroke: none; fill: rgb(0,0,0); fill-opacity: 1;" x="' + (parseInt(offsetX) + 60) + '" y="' + (parseInt(offsetY)) + '">' + barValuesFus[index].toLocaleString('pl-PL') + ' zł</text>'
                                    ));

                                });
                            }
                        });
                    }

                    if($('#chart-base-offer-rest').length > 0) {

                        var barHeightRest = [];
                        var barValuesRest = [];

                        $('#chart-base-offer-rest .c3-chart-bar').each(function () {
                            $(this).find('.c3-bars .c3-bar').each(function (index) {
                                barHeightRest[index] = (barHeightRest[index] || 0) + parseInt($(this).height());
                            });
                        });

                        var chartHeightRest = $('.c3-event-rects').height();
                        var offsetRest = 10;

                        $('#chart-base-offer-rest .c3-chart-text').each(function () {
                            $(this).find('.c3-texts .c3-text').each(function (index) {
                                $(this).attr('y', chartHeightRest - barHeightRest[index] - offsetRest);
                                var valueTabRest = $(this).text().split(' ')[0].replace(/\s/g, '');
                                barValuesRest[index] = (parseInt(barValuesRest[index]) || 0) + parseInt(valueTabRest);
                            });
                            offsetRest += 15;
                        });

                        $('#chart-base-offer-rest .c3-chart-text').each(function (index) {
                            if (index === 0) {
                                $(this).find('.c3-texts .c3-text').each(function (index) {

                                    var offsetY = $(this).attr('y');
                                    var offsetX = $(this).attr('x');

                                    document.querySelector('#chart-base-offer-rest svg').appendChild(parseSVG(
                                        '<text class="c3-text-' + index + '" font-size="14" font-weight="bold" text-anchor="middle" style="stroke: none; fill: rgb(0,0,0); fill-opacity: 1;" x="' + (parseInt(offsetX) + 60) + '" y="' + (parseInt(offsetY)) + '">' + barValuesRest[index].toLocaleString('pl-PL') + ' zł</text>'
                                    ));

                                });
                            }
                        });
                    }
                },
                data: {
                    columns: columns,

                    labels: {
                        format: function(v, id, i, j)
                        {
                            if (isNaN(totals[i])) {totals[i] = 0;}
                            if (isNaN(totalsTime[i])) {totalsTime[i] = 0;}

                            // sum only visible
                            if (chart) {
                                var shown = chart.data.shown().map(function(item){ return item.id; });

                                // if (shown.indexOf(id) != -1) {totals[i] += v;}

                            }
                            else {
                                if(v > 0 && v < 0.5){
                                    totals[i] += Math.ceil(v);

                                } else {
                                    totals[i] += Math.round(v);
                                }
                            }

                            if(offerType === 'time'){

                                if (!isNaN(v) && v) {
                                    return v + ' dni';
                                } else {
                                    return 0 + ' dni';
                                }

                            } else {

                                if(chartType){
                                    return numberWithSpaces(v, chartType);
                                } else {

                                    if(offerType === 'offer' || offerType === 'offer-fus' || offerType === 'offer-rest') {
                                        return numberWithSpaces(Math.round(v) || 0, chartType, true);
                                    } else {
                                        return numberWithSpaces(totals[i], chartType, true);
                                    }
                                }
                            }

                            return true;
                        }
                    },
                    type: 'bar',
                    groups: groups,
                    order: null,
                    colors: {
                        Q1: '#035083',
                        Q2: '#0071bb',
                        Q3: '#28abe2',
                        Q4: '#8bddff'
                    },
                },
                bar: {
                    width: barWidth
                },
                legend: {
                    show: false
                },
                grid:{
                    focus:{
                        show: false
                    }
                },
                axis: {
                    x: {
                        type: 'category',
                        categories: category
                    },
                    y: {
                        tick: percentage,
                        padding: paddingConfigY
                    }
                },
                tooltip: {
                    format: {
                        value: function (value, ratio, id) {

                            if(offerType === 'time'){
                                return numberWithSpacesTime(value);
                            } else {
                                return numberWithSpaces(value, chartType);
                            }
                        }
                    }
                },
                padding: paddingConfig
            });
            showLastLabel(chartType);
            setLegend(columns);
        }

        function init() {
            document.addEventListener('DOMContentLoaded', function() {

                var typeOffer = $('#chart-base-offer').data('typeOffer');
                var dataBaseOffer = $('#chart-base-offer').data('baseOffer');
                var dataInformations = $('#chart-base-offer').data('info');

                var typeOfferFus = $('#chart-base-offer-fus').data('typeOffer');
                var dataBaseOfferFus = $('#chart-base-offer-fus').data('baseOffer');
                var dataInformationsFus = $('#chart-base-offer-fus').data('info');

                var typeOfferRest = $('#chart-base-offer-rest').data('typeOffer');
                var dataBaseOfferRest = $('#chart-base-offer-rest').data('baseOffer');
                var dataInformationsRest = $('#chart-base-offer-rest').data('info');

                var tq1 = [qLabels[0]],
                    tq2 = [qLabels[1]],
                    tq3 = [qLabels[2]],
                    tq4 = [qLabels[3]],

                    tq1Fus = [qLabels[0]],
                    tq2Fus = [qLabels[1]],
                    tq3Fus = [qLabels[2]],
                    tq4Fus = [qLabels[3]],

                    tq1Rest = [qLabels[0]],
                    tq2Rest = [qLabels[1]],
                    tq3Rest = [qLabels[2]],
                    tq4Rest = [qLabels[3]],

                    categoryTemp = [],
                    categoryFusTemp = [],
                    categoryRestTemp = [];

                // for base offer ----------------------------------------
                var realData = [];
                var localCounterTab = [];



                for (var keyB in dataBaseOffer) {
                    if (dataBaseOffer.hasOwnProperty(keyB)) {

                        var k = Object.keys(dataBaseOffer).indexOf(keyB);

                        //var valueCounter = 0;
                        var localCounter = 0;

                        //if(typeOffer !== 'service'){
                        categoryTemp.push(keyB);
                        //}

                        for (var keyU in dataBaseOffer[keyB]) {
                            if (dataBaseOffer[keyB].hasOwnProperty(keyU)) {

                                if(!!dataBaseOffer[keyB][keyU]){

                                    if(dataBaseOffer[keyB][keyU].value > 0) {
                                        localCounter +=1;
                                    }

                                    if(dataBaseOffer[keyB][keyU].quarter === 1){
                                        tq1.push(dataBaseOffer[keyB][keyU].value);
                                    }
                                    if(dataBaseOffer[keyB][keyU].quarter === 2){
                                        tq2.push(dataBaseOffer[keyB][keyU].value);
                                    }
                                    if(dataBaseOffer[keyB][keyU].quarter === 3){
                                        tq3.push(dataBaseOffer[keyB][keyU].value);
                                    }
                                    if(dataBaseOffer[keyB][keyU].quarter === 4){
                                        tq4.push(dataBaseOffer[keyB][keyU].value);
                                    }
                                }
                            }
                        }

                        localCounterTab.push(localCounter);
                    }
                }

                // set categories array

                var category = [];

                for (var s = 0; s <=  localCounterTab.length; ++s) {

                    if(localCounterTab[s] > 0){
                        category.push(categoryTemp[s]);
                    }
                }

                localCounterTab.unshift(1);

                var localCounterTabFiltered = localCounterTab.filter(function(item) {
                    return (item !== null);
                });

                var q1 = [],
                    q2 = [],
                    q3 = [],
                    q4 = [];

                for (var b = 0; b <=  localCounterTabFiltered.length; ++b) {
                    if(localCounterTabFiltered[b] > 0){
                        if(tq1.length > 1){

                            if(tq1[b]){
                                q1.push(tq1[b]);
                            } else {
                                q1.push(null);
                            }
                        }
                        if(tq2.length > 1){

                            if(tq2[b]){
                                q2.push(tq2[b]);
                            } else {
                                q2.push(null);
                            }
                        }
                        if(tq3.length > 1){

                            if(tq3[b]) {
                                q3.push(tq3[b]);
                            } else {
                                q3.push(null);
                            }
                        }
                        if(tq4.length > 1){

                            if(tq4[b]){
                                q4.push(tq4[b]);
                            } else {
                                q4.push(null);
                            }
                        }
                    }
                }

                if(typeOffer === 'time'){
                    for(var e = 0; e < category.length; e++){
                        $('.custom-legend').append('<li><a href="#" class=""></a><p>Czas liczony od momentu zarejestrowania reklamacji do czasu jej zakończenia</p></li>');
                    }
                }

                // for FUS offer ---------------------------------------------------------------------------------------
                var realDataFus = [];

                var localCounterTabFus = [];

                for (var keyF in dataBaseOfferFus) {
                    if (dataBaseOfferFus.hasOwnProperty(keyF)) {

                        var j = Object.keys(dataBaseOfferFus).indexOf(keyF);
                        var localCounterFus = 0;

                        categoryFusTemp.push(keyF);

                        for (var keyT in dataBaseOfferFus[keyF]) {
                            if (dataBaseOfferFus[keyF].hasOwnProperty(keyT)) {

                                if(!!dataBaseOfferFus[keyF][keyT] && (keyF === "Rabat FUS" || keyF === "Rabat stały kontraktowy")){

                                    if(dataBaseOfferFus[keyF][keyT].value > 0) {
                                        localCounterFus += 1;
                                    }

                                    if(dataBaseOfferFus[keyF][keyT].quarter === 1){
                                        tq1Fus.push(dataBaseOfferFus[keyF][keyT].value);
                                    }
                                    if(dataBaseOfferFus[keyF][keyT].quarter === 2){
                                        tq2Fus.push(dataBaseOfferFus[keyF][keyT].value);
                                    }
                                    if(dataBaseOfferFus[keyF][keyT].quarter === 3){
                                        tq3Fus.push(dataBaseOfferFus[keyF][keyT].value);
                                    }
                                    if(dataBaseOfferFus[keyF][keyT].quarter === 4){
                                        tq4Fus.push(dataBaseOfferFus[keyF][keyT].value);
                                    }
                                } else {
                                    localCounterFus = null;
                                }
                            }
                        }

                        localCounterTabFus.push(localCounterFus);
                    }
                }

                // set categories array

                var categoryFus = [];

                for (var p = 0; p <=  localCounterTabFus.length; ++p) {

                    if(localCounterTabFus[p] > 0){
                        categoryFus.push(categoryFusTemp[p]);
                    }
                }

                localCounterTabFus.unshift(1);

                var localCounterTabFusFiltered = localCounterTabFus.filter(function(item) {
                    return (item !== null);
                });


                var q1Fus = [],
                    q2Fus = [],
                    q3Fus = [],
                    q4Fus = [];

                for (var m = 0; m <=  localCounterTabFusFiltered.length; ++m) {

                    if(localCounterTabFusFiltered[m] > 0){
                        if(tq1Fus.length > 1){

                            if(tq1Fus[m]){
                                q1Fus.push(tq1Fus[m]);
                            } else {
                                q1Fus.push(null);
                            }
                        }
                        if(tq2Fus.length > 1){

                            if(tq2Fus[m]){
                                q2Fus.push(tq2Fus[m]);
                            } else {
                                q2Fus.push(null);
                            }
                        }
                        if(tq3Fus.length > 1){

                            if(tq3Fus[m]) {
                                q3Fus.push(tq3Fus[m]);
                            } else {
                                q3Fus.push(null);
                            }
                        }
                        if(tq4Fus.length > 1){

                            if(tq4Fus[m]){
                                q4Fus.push(tq4Fus[m]);
                            } else {
                                q4Fus.push(null);
                            }

                        }
                    }
                }

                // for Rest offer --------------------------------------------------------------------------------------------------------------------------------
                var realDataRest = [];
                var localCounterTabRest = [];

                for (var keyR in dataBaseOfferRest) {
                    if (dataBaseOfferRest.hasOwnProperty(keyR)) {

                        var i = Object.keys(dataBaseOfferRest).indexOf(keyR);

                        var localCounterRest = 0;

                        categoryRestTemp.push(keyR);

                        for (var keyS in dataBaseOfferRest[keyR]) {
                            if (dataBaseOfferRest[keyR].hasOwnProperty(keyS)) {

                                if(!!dataBaseOfferRest[keyR][keyS] && (keyR === "Cenniki" || keyR === "Synoptis+" || keyR === "Korzyści z dystrybucji" || keyR === "Dokładka do ZP - centralne" || keyR === "Dokładka do ZP - lokalne" || keyR === "Promocje" || keyR === "Zysk+")){

                                    if(dataBaseOfferRest[keyR][keyS].value > 0) {

                                        localCounterRest += 1;
                                    }
                                    if(dataBaseOfferRest[keyR][keyS].quarter === 1){
                                        tq1Rest.push(dataBaseOfferRest[keyR][keyS].value);
                                    }
                                    if(dataBaseOfferRest[keyR][keyS].quarter === 2){
                                        tq2Rest.push(dataBaseOfferRest[keyR][keyS].value);
                                    }
                                    if(dataBaseOfferRest[keyR][keyS].quarter === 3){
                                        tq3Rest.push(dataBaseOfferRest[keyR][keyS].value);
                                    }
                                    if(dataBaseOfferRest[keyR][keyS].quarter === 4){
                                        tq4Rest.push(dataBaseOfferRest[keyR][keyS].value);
                                    }

                                } else {
                                    localCounterRest = null;
                                }
                            }
                        }

                        localCounterTabRest.push(localCounterRest);
                    }
                }

                var categoryRest = [];

                for (var r = 0; r <=  localCounterTabRest.length; ++r) {

                    if(localCounterTabRest[r] > 0){
                        categoryRest.push(categoryRestTemp[r]);
                    }
                }

                localCounterTabRest.unshift(1);


                var localCounterTabRestFiltered = localCounterTabRest.filter(function(item) {
                    return (item !== null);
                });

                var q1Rest = [],
                    q2Rest = [],
                    q3Rest = [],
                    q4Rest = [];

                for (var n = 0; n <=  localCounterTabRestFiltered.length; ++n) {

                    if(localCounterTabRestFiltered[n] > 0){
                        if(tq1Rest.length > 1){

                            if(tq1Rest[n]){
                                q1Rest.push(tq1Rest[n]);
                            } else {
                                q1Rest.push(null);
                            }
                        }
                        if(tq2Rest.length > 1){

                            if(tq2Rest[n]){
                                q2Rest.push(tq2Rest[n]);
                            } else {
                                q2Rest.push(null);
                            }
                        }
                        if(tq3Rest.length > 1){

                            if(tq3Rest[n]) {
                                q3Rest.push(tq3Rest[n]);
                            } else {
                                q3Rest.push(null);
                            }
                        }
                        if(tq4Rest.length > 1){

                            if(tq4Rest[n]){
                                q4Rest.push(tq4Rest[n]);
                            } else {
                                q4Rest.push(null);
                            }
                        }
                    }
                }

                if(typeOffer === 'service'){

                    q1 = q1.slice(0, 3);
                    q2 = q2.slice(0, 3);
                    q3 = q3.slice(0, 3);
                    q4 = q4.slice(0, 3);

                    var q = [q1, q2, q3, q4];

                    q.forEach(function(item){
                        if(item.length > 1) {
                            realData.push(item);
                        }
                    });

                    if(localCounterTab[1] > 0) {
                        $('.chart-header-desc .one').css('display','inline-block');
                    }

                    if(localCounterTab[2] > 0) {
                        $('.chart-header-desc .two').css('display','inline-block');
                    }

                    var dataInformationsService = [];


                    category.forEach(function(item){
                        if(item === 'Udział terminowych dostaw' || item === 'Termin ważności produktów') {
                            dataInformationsService.push(item);
                        }
                    });

                    setLegendDescription(category, dataInformations, 'service', localCounterTab, dataInformationsService);

                } else {

                    setLegendDescription(category, dataInformations, 'all', localCounterTab);
                    setLegendDescription(categoryFus, dataInformationsFus, 'fus', localCounterTabFus);
                    setLegendDescription(categoryRest, dataInformationsRest, 'rest', localCounterTabRest);

                    //temp data
                    // q1 = ['Q1','100','120','320'];
                    // q2 = ['Q2','200','110','500'];
                    // q3 = ['Q3','430','40','100'];
                    // q4 = ['Q4','220','120','80'];

                    if (q1.length > 1) {realData.push(q1);}
                    if (q2.length > 1) {realData.push(q2);}
                    if (q3.length > 1) {realData.push(q3);}
                    if (q4.length > 1) {realData.push(q4);}

                    if (q1Fus.length > 1) {realDataFus.push(q1Fus);}
                    if (q2Fus.length > 1) {realDataFus.push(q2Fus);}
                    if (q3Fus.length > 1) {realDataFus.push(q3Fus);}
                    if (q4Fus.length > 1) {realDataFus.push(q4Fus);}

                    if (q1Rest.length > 1) {realDataRest.push(q1Rest);}
                    if (q2Rest.length > 1) {realDataRest.push(q2Rest);}
                    if (q3Rest.length > 1) {realDataRest.push(q3Rest);}
                    if (q4Rest.length > 1) {realDataRest.push(q4Rest);}

                }

                if($('#chart-base-offer').length > 0){


                    // var realDataFiltered = [];
                    //
                    // for (var i = 0; i < realData.length ; i++) {
                    //
                    //     if (realData[i][0] === undefined) {
                    //         realDataFiltered.push(null);
                    //     } else {
                    //         realDataFiltered.push(realData[i][0]);
                    //     }
                    // }


                    drawChart(realData, category, typeOffer === 'service', typeOffer);
                }

                if($('#chart-base-offer-fus').length > 0){
                    drawChart(realDataFus, categoryFus, typeOfferFus === 'service', typeOfferFus);
                }

                if($('#chart-base-offer-rest').length > 0){
                    drawChart(realDataRest, categoryRest, typeOfferRest === 'service', typeOfferRest);
                }
            });
        }

        return {
            init: init
        };

    })();

    // network vip chart (VIP data removed from chart)

    app.chartNetworkVip = (function(){

        function drawChart(percentage_columns, percentage_values, chartType){

            var chart = c3.generate({
                bindto: '#chart-base-offer-vip',
                data: {
                    columns: percentage_columns,
                    type: 'donut',
                    order: null,
                    labels: false
                },
                color: {
                    pattern: ['#035083', '#0071BB', '#28ABE2']
                },
                legend: {
                    show: true,
                    position: 'inset',
                    inset: {
                        anchor: 'top-right',
                        x: -65,
                        y: 80
                    }
                },
                grid: {
                    focus:{
                        show: false
                    }
                },
                padding: {
                    bottom: 30,
                    right: 150
                },
                tooltip: {
                    format: {
                        value: function (value, ratio, id) {
                            var sup;
                            if(id ==='1 dostawa') {
                                sup = percentage_values[0][1];
                            }
                            if(id ==='2 dostawy') {
                                sup = percentage_values[1][1];
                            }
                            if(id ==='3 i więcej dostaw') {
                                sup = percentage_values[2][1];
                            }

                            if(parseInt(sup) === 1) {
                                sup = parseInt(sup) + " apteka";
                            } else {
                                sup = parseInt(sup) + " aptek";
                            }
                            return sup;
                        }
                    }
                },
            });
        }

        function init() {
            document.addEventListener('DOMContentLoaded', function() {

                var one = $('#chart-base-offer-vip').data('one');
                var two = $('#chart-base-offer-vip').data('two');
                var three = $('#chart-base-offer-vip').data('three');

                var typeOffer;
                var p1 = ['1 dostawa'];
                var p2 = ['2 dostawy'];
                var p3 = ['3 i więcej dostaw'];
                var v1 = ['1 dostawa'];
                var v2 = ['2 dostawy'];
                var v3 = ['3 i więcej dostaw'];

                v1.push(one.value);
                p1.push(one.persent);

                v2.push(two.value);
                p2.push(two.persent);

                v3.push(three.value);
                p3.push(three.persent);

                var columns_percentage = [p1, p2, p3];
                var columns_values = [v1, v2, v3];

                if(columns_percentage.length > 0){

                    drawChart(columns_percentage, columns_values, typeOffer === 'vip');
                } else {
                    $('#chart-base-offer-vip').closest('.chart-right').hide();
                    $('.chart-left').css('margin', 'auto');
                    $('.desc-container .desc-left').css('margin', 'auto');
                    $('.desc-container .desc-right').css('display', 'none');
                }
            });
        }

        return {
            init: init
        };

    })();

    // app.chartNetworkVip = (function(){
    //
    //     function drawChart(columns, category, chartType){
    //
    //         var groups = [['TAK','NIE']];
    //
    //         var chart = c3.generate({
    //             bindto: '#chart-base-offer-vip',
    //             data: {
    //                 columns: columns,
    //                 type: 'bar',
    //                 groups: groups,
    //                 order: null,
    //                 colors: {
    //                     TAK: '#035083',
    //                     NIE: '#A9A9A9'
    //                 },
    //                 labels: false
    //             },
    //             bar: {
    //                 width: 30
    //             },
    //             legend: {
    //                 show: true,
    //                 position: 'inset',
    //                 inset: {
    //                     anchor: 'top-right',
    //                     x: -65,
    //                     y: 80
    //                 }
    //             },
    //             grid: {
    //                 focus:{
    //                     show: false
    //                 }
    //             },
    //             axis: {
    //                 x: {
    //                     type: 'category',
    //                     categories: category
    //                 }
    //             },
    //             padding: {
    //                 bottom: 30,
    //                 right: 100
    //             },
    //         });
    //     }
    //
    //     function setLegend(columns){
    //         columns.forEach(function(item){
    //             var legendItems = $('.chart-header-legend');
    //             legendItems.find('.' + item[0]).addClass('active');
    //         });
    //     }
    //
    //     function init() {
    //         document.addEventListener('DOMContentLoaded', function() {
    //
    //             var typeOffer = $('#chart-base-offer').data('typeOffer');
    //             var dataBaseOffer = $('#chart-base-offer').data('baseOffer');
    //             var pharmaCount;
    //
    //             var t1 = [],
    //                 t2 = ['NIE'],
    //
    //                 category = [];
    //
    //             for (var key in dataBaseOffer) {
    //                 if (dataBaseOffer.hasOwnProperty(key)) {
    //                     if(!!dataBaseOffer[key][dataBaseOffer[key].length - 1] && key !== "Obsługa VIP magazyn"){
    //                         t1.push(parseInt(dataBaseOffer[key][dataBaseOffer[key].length - 1].value));
    //                         category.push(key);
    //                     }
    //
    //                     if(key === "Obsługa VIP magazyn"){
    //                         pharmaCount = dataBaseOffer[key][dataBaseOffer[key].length - 1].value;
    //                     }
    //                 }
    //             }
    //
    //             category = category.slice(2, 10);
    //             t1 = t1.slice(2, 10);
    //
    //             //temporary q1 and pharmaCount
    //             // t1 = [8,2,2,5,4,1,3,];
    //             // pharmaCount = 8;
    //
    //             if(typeof(pharmaCount) === 'undefined'){
    //                 pharmaCount = 0;
    //             }
    //
    //             $('#pharma-count').html(parseInt(pharmaCount));
    //
    //             t1.forEach(function(elem) {
    //                 var participationValue;
    //                 if(parseInt(pharmaCount) > elem){
    //                     participationValue = parseInt(pharmaCount) - elem;
    //                 } else {
    //                     participationValue = 0;
    //                 }
    //
    //                 t2.push(participationValue);
    //             });
    //
    //             t1.unshift('TAK');
    //
    //             var realData = [];
    //             if (t1.length > 1) {
    //                 realData.push(t1);
    //                 realData.push(t2);
    //             }
    //
    //             if(parseInt(pharmaCount) > 0){
    //
    //                 drawChart(realData, category, typeOffer === 'vip');
    //             } else {
    //                 $('#chart-base-offer-vip').closest('.chart-right').hide();
    //                 $('.chart-left').css('margin', 'auto');
    //                 $('.desc-container .desc-left').css('margin', 'auto');
    //                 $('.desc-container .desc-right').css('display', 'none');
    //             }
    //         });
    //     }
    //
    //     return {
    //         init: init
    //     };
    //
    // })();

    app.modal = (function() {

        var $modal = $('.modal'),
            $modalResignation = $('.resignation-form'),
            $modalSelected = $('.related-form'),
            $closeBtn = $modal.find('.modal-close'),
            $cancelBtn = $modal.find('.modal-cancel'),
            $overlay = $('.modal-overlay'),
            $pharmacyName = $modal.find('#pharmacy_name'),
            $modalSelectedField = $modalSelected.find('#pharmacyName'),
            $formDesc = $modal.find('textarea'),
            $pharmacyId = $modal.find('#resignation_pharmacy'),
            $modalResignationTriggers = $('.benefit-content .disable-item'),
            $modalSelectedTrigger = $('.submit-choice'),
            $formSend = $modalResignation.find('#resignation_send'),
            $formSendSelected = $modalSelected.find('#selected_send'),
            modalOpenClasses = 'modal-open modal-resignation modal-related',
            $relatedCheckboxes = $('.benefit-content .item-checkbox'),
            $relatedErrorMessage = $('.related-error-message'),

            data = {
                id: 0,
                name: ""
            },

            localStorageCheckboxesKey = 'selectedCheckboxes';

        function selectCheckboxesFromLS() {
            var checkedBoxes = JSON.parse(localStorage.getItem(localStorageCheckboxesKey)),
                setDateMax = 60 * 1000 * 120, // 2 hours
                setDate,
                currDate = new Date();

            function setNewLS() {
                setDate = '' + currDate.getTime();
                localStorage.setItem(localStorageCheckboxesKey, JSON.stringify({
                    setDate: setDate
                }));
            }

            if (checkedBoxes && checkedBoxes.setDate) {
                setDate = new Date(parseInt(checkedBoxes.setDate, 10));
                if ((currDate - setDate) <= setDateMax) {
                    for (var checkbox in checkedBoxes) {
                        var elem = $('#' + checkbox);
                        if (elem.length && checkedBoxes.hasOwnProperty(checkbox)) {
                            elem.prop('checked', true);
                        }
                    }
                } else {
                    setNewLS();
                }
            } else {
                setNewLS();
            }
        }

        function addCheckboxToLS(checkboxId) {
            var checkedBoxes = JSON.parse(localStorage.getItem(localStorageCheckboxesKey));
            checkedBoxes[checkboxId] = true;
            localStorage.setItem(localStorageCheckboxesKey, JSON.stringify(checkedBoxes));
        }

        function removeCheckboxFromLS(checkboxId) {
            var checkedBoxes = JSON.parse(localStorage.getItem(localStorageCheckboxesKey));
            delete checkedBoxes[checkboxId];
            localStorage.setItem(localStorageCheckboxesKey, JSON.stringify(checkedBoxes));
        }

        function clearCheckedFromLS() {
            localStorage.removeItem(localStorageCheckboxesKey);
        }

        function modalClose() {
            $('body').removeClass(modalOpenClasses);
        }

        function modalOpen(type) {
            if (type === 'resignation') {
                $formDesc.val("");
                $pharmacyId.val(data.id);
                $pharmacyName.text(data.name);
                $('body').addClass('modal-open modal-resignation');
            } else {

                $('#modal-form').submit();


                //$('body').addClass('modal-open modal-related');
            }
        }

        function modalTriggerResignation(ev) {
            ev.preventDefault();
            data.id = ev.target.getAttribute('data-id');
            data.name = ev.target.getAttribute('data-name');
            modalOpen('resignation');
        }

        function modalTriggerRelated(ev) {
            ev.preventDefault();
            var canOpen = checkMultipleSelected();

            if (canOpen) {
                modalOpen();
            } else {
                showRelatedError();
            }

        }

        function modalTriggerClose(ev) {
            ev.preventDefault();
            removeHiddenCheckboxes();
            modalClose();
        }

        function validateTextField(ev) {
            if ($.trim($formDesc.val()).length < 1) {
                ev.preventDefault();
                showError($formDesc);
            }
        }

        function validateSelectedField(ev) {
            //var $field = $modalSelected.find('#pharmacyName');

            //console.log(ev, $modalSelectedField, $.trim($modalSelectedField.val()));

            if ($.trim($modalSelectedField.val()).length < 1) {
                ev.preventDefault();
                showError($modalSelectedField);
                $modalSelectedField.parent().addClass('form-error');
            } else {
                //form is posted
                clearCheckedFromLS();
            }
        }

        function showError($el) {
            $el.parent().addClass('form-error');
        }

        function hideError() {
            if ($formDesc.parent().hasClass('form-error')) {
                $formDesc.parent().removeClass('form-error');
            }
            if ($modalSelectedField.parent().hasClass('form-error')) {
                $modalSelectedField.parent().removeClass('form-error');
            }
        }

        function checkMultipleSelected() {
            var checked = 0,
                checkedIds = [];

            $relatedCheckboxes.each(function(i, el) {
                var isChecked = $(el).find('input[type="checkbox"]').prop('checked');
                el.addEventListener('change', hideRelatedError);
                if (isChecked) {
                    checked++;
                    checkedIds.push($(el).find('input[type="checkbox"]').val());
                }
            });
            getSelectedIds(checkedIds);

            return checked > 0 ? checkedIds : checked;
        }

        function getSelectedIds(idArr) {
            if (idArr.length) {
                var inputs = '';
                idArr.map(function(el, i) {
                    inputs += generateHiddenCheckbox(el);
                });

                $modalSelected.find('form').prepend(inputs);
            }
        }

        function removeHiddenCheckboxes() {
            var checkboxes = $modalSelected.find('input[type=hidden]');

            if (checkboxes.length) {
                checkboxes.each(function() {
                    $(this).remove();
                });
            }
        }

        function generateHiddenCheckbox(pharmacyId) {
            return '<input type="hidden" id="checkbox-' + pharmacyId + '" name="pharmacies[]" value="' + pharmacyId + '" />';
        }

        function showRelatedError() {
            $relatedErrorMessage.fadeIn(200);
        }

        function hideRelatedError() {
            $relatedErrorMessage.fadeOut(200);
        }

        function addEvents() {
            if ($modalResignationTriggers.length && $modalResignation.length) {

                $modalResignationTriggers.toArray().map(function(el) {
                    el.addEventListener('click', modalTriggerResignation);
                });

                $formSend.on('click', validateTextField);
                $formDesc.on('keyup', hideError);
            }

            if ($modalSelectedTrigger.length && $modalSelected.length) {
                $modalSelectedTrigger.on('click', modalTriggerRelated);
                //validateSelectedField
                $formSendSelected.on('click', validateSelectedField);
                $modalSelectedField.on('keyup', hideError);
            }

            if ($relatedCheckboxes.length) {
                $relatedCheckboxes.click(function() {
                    var checkbox = $(this).find('input[type="checkbox"]'),
                        checked = checkbox.prop('checked'),
                        id = checkbox.attr('id');

                    if (checked) {
                        addCheckboxToLS(id);
                    } else {
                        removeCheckboxFromLS(id);
                    }
                });
            }

            $closeBtn.on('click', modalTriggerClose);
            $cancelBtn.on('click', modalTriggerClose);
            $overlay.on('click', modalTriggerClose);
        }

        function init() {
            addEvents();
            selectCheckboxesFromLS();
        }

        return {
            init: init
        };

    })();

    app.pharmacies = (function() {

        if (!String.prototype.contains) {
            String.prototype.contains = function(s, i) {
                return this.indexOf(s, i) != -1;
            };
        }

        var form = $('#select_pharmacy_form'),
            sendUrl = form.attr('action');

        function visibleDataSearch() {
            var filtrVal = [],
                filtrName = [],
                countSelectControl = 0;


            $('.benefit-search input').each(function(i) {
                countSelectControl++;
                filtrVal.push($(this).val());
                filtrName.push($(this).attr('name'));
            });

            $('.benefit-list .benefit-content').each(function(i) {
                var thisIsTrue = true;
                for (var c = 0; c < countSelectControl; c++) {
                    if ($(this).find('.' + filtrName[c]).html().contains(filtrVal[c]) && !filtrVal[c].contains('none')) {
                        thisIsTrue = thisIsTrue & true;
                    } else if (!filtrVal[c].contains('none')) {
                        thisIsTrue = thisIsTrue & false;
                    }
                }
                if (!thisIsTrue) {
                    $(this).hide();
                } else {
                    $(this).show();
                }
            });
        }

        function pharmaciesListScroll() {
            var container = $('.benefit-list');
            if (container.length > 0) {
                try {
                    container.fakeScroll();
                } catch (error) {
                    console.log(error);
                }
            }
        }

        function formSubmit() {
            form.trigger('submit');
        }

        function addListeners() {

            $(document).on('change', '#select_pharmacy_city', function() {
                formSubmit();
            });

            $(document).on('change', '#select_pharmacy_state', function() {
                formSubmit();
            });

            $(document).on('change', '#select_pharmacy_sapId', function() {
                formSubmit();
            });

            $(document).on('change', '#select_pharmacy_connectionId', function() {
                formSubmit();
            });

            $(document).on('change', '#select_pharmacy_pharmacySapName', function() {
                formSubmit();
            });

            $(document).on('change', '#select_pharmacy_pharmacyConnectionName', function() {
                formSubmit();
            });

            //added

            $(document).on('change', '#select_pharmacy_pharmacy', function() {
                formSubmit();
            });

            $(document).on('change', '#select_pharmacy_agent', function(event) {
                var selectedOption = event.target.options.selectedIndex,
                    userId = event.target.options[selectedOption].value;
                app.manageUsers.setUser(userId);
                formSubmit();
            });
        }

        function init() {
            visibleDataSearch();
            pharmaciesListScroll();
            addListeners();
        }

        return {
            sendUrl: sendUrl,
            formSubmit: formSubmit,
            init: init
        };

    })();

    app.showBenefits = (function() {

        var $hidden = $('.hidden'),
            $toHide = $('.to-hide'),
            $calcBtn = $('.calculate-benefits');

        function init() {
            $calcBtn.on('click', function() {
                $hidden.addClass('shown');
                $toHide.hide();
            });
        }

        return {
            init: init
        };

    })();

    app.manageUsers = (function() {

        var agentSelect = $('#select_pharmacy_agent');

        function sendData(sendUrl, data) {
            $.ajax({
                url: sendUrl,
                data: data,
            }).done(function(response) {

            });
        }

        function sendUserToServer(userId) {
            userId = userId || getUser();
            var url = '';
            if (typeof userId !== 'undefined' && userId !== null && userId.length > 0) {
                url = window.location.origin + "/auth/local-storage/" + userId;
            } else {
                url = window.location.origin + "/auth/local-storage";
            }
            sendData(url, null);
        }

        function setUser(userId) {
            if (typeof userId !== 'undefined' && getUser() !== userId) {
                localStorage.setItem("userId", userId);
            } else {
                console.log("Not a valid username.");
            }
        }

        function getUser() {
            return localStorage.getItem("userId");
        }

        function setActiveSelect(userId) {
            agentSelect.val(userId);
        }

        function verifyUser() {
            var userId = getUser();
            if (userId !== null) {
                sendUserToServer(userId);
                setActiveSelect(userId);
            }
        }

        function init() {
            verifyUser();
        }

        return {
            setUser: setUser,
            init: init
        };

    })();

    app.autocomplete = (function(){
        var params = {},
            pairs = window.location.search.split('&');

        function setParam(paramString) {
            var split = paramString.replace('?', '').split('=');
            params[decodeURIComponent(split[0])] = decodeURIComponent(split[1]);
        }

        function getParams() {
            pairs.map(setParam);
        }

        function filterOptions() {
            var $options = $('#select_pharmacy_sapId option'),
                hasSap = params.hasOwnProperty('sap'),
                hasConnection = params.hasOwnProperty('connection');

            if (hasSap || hasConnection) {
                $options.each(function(i, option) {
                    if (!(option.value === params.sap || option.value === params.connection)) {
                        option.remove();
                    }
                });
            }
        }

        function init() {

            // filter select options if needed
            //getParams();
            //filterOptions();

            $("#select_pharmacy_sapId").select2({
                "language": "pl",
            });

            $("#select_pharmacy_pharmacy").select2({
                "language": "pl",
            });

            $("#select_pharmacy_connectionId").select2({
                "language": "pl",
            });

            $("#select_pharmacy_pharmacySapName").select2({
                "language": "pl",
            });

            $("#select_pharmacy_pharmacyConnectionName").select2({
                "language": "pl",
            });
        }


        return {
            init: init
        };

    })();


    app.dynamicSlide = (function(){

        var selectedOptionsTab = [];

        function addTab(el, all){

            if(all === true){
                selectedOptionsTab = [0,1,2,3,4,5,6,7,8,9,10,11,12];
            } else {
                selectedOptionsTab.push(el);
            }
        }

        function removeTab(el,all){

            if(all === true){
                selectedOptionsTab = [];
            } else {
                for(var i = 0; i < selectedOptionsTab.length; i++){
                    if ( selectedOptionsTab[i] === el) {
                        selectedOptionsTab.splice(i, 1);
                        i--;
                    }
                }
            }
        }

        function presentData(data){

            if(selectedOptionsTab.length === 0){
                $('#rabat-korzysci, #rabat-korzysci-all ').text('');
                $('#rabat-korzysci-procent, #rabat-korzysci-procent-all').text('');
                $('.loading-ico').removeClass('show');
            } else {
                $('.loading-ico').removeClass('show');
                $('#rabat-korzysci, #rabat-korzysci-all ').text(data.rabat_korzysci.toLocaleString('pl-PL') + ' zł');
                $('#rabat-korzysci-procent, #rabat-korzysci-procent-all').text(data.rabat_korzysci_procent + ' %');
            }
        }

        function getValues(pharmacyId, quarter, year){
            $.ajax({
                url: '/api/benefits/'+ pharmacyId +'/'+ quarter +'/'+ year +'?id=' + selectedOptionsTab,
                type: 'GET',
                dataType: 'json',
                success: function(data) {
                    presentData(data);
                }
            });
        }

        function init() {

            var pharmacyId = $('.all-discounts-options').data('id');
            var quarter = $('.all-discounts-options').data('quarter');
            var year = $('.all-discounts-options').data('year');

            $('.all-discounts-options li').click(function(){

                $('.loading-ico').addClass('show');
                $('#rabat-korzysci, #rabat-korzysci-all ').text('');
                $('#rabat-korzysci-procent, #rabat-korzysci-procent-all').text('');

                if($(this).hasClass('selected')){
                    $(this).removeClass('selected');
                    if($(this).hasClass('options-all')){
                        removeTab($(this).data('id'), true);
                        $(this).siblings().not('.empty').removeClass('selected');
                    } else {
                        removeTab($(this).data('id'));
                    }
                    getValues(pharmacyId, quarter, year);
                } else {

                    $(this).addClass('selected');

                    if($(this).hasClass('options-all')){
                        addTab($(this).data('id'), true);
                        $(this).siblings().not('.empty').addClass('selected');
                    } else {
                        addTab($(this).data('id'));
                    }
                    getValues(pharmacyId, quarter, year);
                }
            });
        }

        return {
            init: init
        };
    })();


    app.generatePDF = (function(){

        function init() {

            var listButton = $('.pdf-generator a.list');
            var activeButton = $('.pdf-generator a.save');
            var pdfButton = $('.pdf-generator a.pdf');

            var slide = activeButton.parent().data('slideid');
            var agentId = activeButton.parent().data('agent');
            var pharmacyId = activeButton.parent().data('id');
            var pharmacyName = activeButton.parent().data('name');
            var pdfArrayOld = JSON.parse(sessionStorage.getItem('pdfArray')) || [];

            if(pdfArrayOld.length > 0 && pdfArrayOld[0].pharmacyId != pharmacyId) {
                sessionStorage.removeItem('pdfArray');
            }

            function isSelected(){
                var item = 0;
                pdfArrayOld.forEach(function(el){
                    if(el.slideName === slide){
                        item+=1;
                    }
                });
                return item;
            }

            function setActiveIcons(){
                var pdfArrayOld = JSON.parse(sessionStorage.getItem('pdfArray')) || [];

                if(pdfArrayOld.length > 0){
                    pdfButton.addClass('active');
                    listButton.addClass('active');

                    $('.pdf-list ul li').hide();

                    pdfArrayOld.forEach(function(el){
                        $('.pdf-list ul li').each(function(){
                            if($(this).data('slideid') == el.slideName){
                                $(this).show();
                            }
                        });
                    });

                } else {
                    pdfButton.removeClass('active');
                    listButton.removeClass('active');
                }

                if(isSelected()){
                    activeButton.addClass('active');
                } else {
                    activeButton.removeClass('active');
                }
            }

            setActiveIcons();


            function sendPdfRequest() {

                var pdfArrayOld = JSON.parse(sessionStorage.getItem('pdfArray')) || [];
                var pdfArrayLinks = [];

                pdfArrayOld.forEach(function(item) {
                    pdfArrayLinks.push(item.filePath);
                });

                // var pharmacyNameParsed = pharmacyName.replace(/ /g, '_') + '_' + pharmacyId;
                var pharmacyNameParsed = 'prezentacja_' + pharmacyId;

                pdfButton.addClass('loading');
                $('body').addClass('frozen');
                    axios({
                    method: 'POST',
                    url: '/slide/generate-pdf',
                    data: {
                        links: pdfArrayLinks,
                        name: pharmacyNameParsed
                    },
                    headers: {
                        'PHPSESSID': $('.token').data('token'),
                        'Content-Type': 'application/json'
                    },

                }).then(function(response){

                    sessionStorage.removeItem('pdfArray');
                    var url = response.data.url;
                    var link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', pharmacyNameParsed + '.pdf');
                    document.body.appendChild(link);
                    link.click();
                        pdfButton.removeClass('loading');
                        $('body').removeClass('frozen');
                        setActiveIcons();
                        activeButton.removeClass('active');
                }).catch(function (error) {
                        console.log(error);
                        pdfButton.removeClass('loading');
                        $('body').removeClass('frozen');
                });
            }

            pdfButton.click(function(e){
                sendPdfRequest();
                e.preventDefault();
            });

            activeButton.click(function(e){

                setActiveIcons();

                function filter (node) {
                    return (
                        node.className !== 'back-button' &&
                        node.className !== 'pdf-generator' &&
                        node.className !== 'arrows' &&
                        node.className !== 'back-btn'
                    );
                }

                function sendImage(blob) {
                    var formData = new FormData();
                    var filename = pharmacyId + "_" + agentId + "_" + slide + ".png";
                    formData.append('media', blob, filename);

                    function removeExisted(item) {
                        return (item.slideName !== slide);
                    }

                    if(isSelected()){
                        pdfArrayOld = pdfArrayOld.filter(removeExisted);
                        sessionStorage.setItem('pdfArray', JSON.stringify(pdfArrayOld));
                        setActiveIcons();
                    } else {

                    activeButton.addClass('loading');
                    $('body').addClass('frozen');

                    axios.post('/slide/file', formData, {
                        method: "POST",
                        headers: {
                            'PHPSESSID': $('.token').data('token'),
                            'Content-Type': 'multipart/form-data'
                        },
                    })
                        .then(function (response){
                            activeButton.removeClass('loading');
                            $('body').removeClass('frozen');

                            var slideItem = {
                                'filePath': response.data.url,
                                'slideName': slide,
                                'pharmacyId': pharmacyId
                            };

                            pdfArrayOld.push(slideItem);
                            sessionStorage.setItem('pdfArray', JSON.stringify(pdfArrayOld));
                            setActiveIcons();

                            $('.c3-chart-legend.all li a').each(function(){
                                if($(this).next().text() !== ''){
                                    $(this).removeClass('run');
                                }
                            });
                        })
                        .catch(function (error) {
                            activeButton.removeClass('loading');
                            $('body').removeClass('frozen');
                        });
                    }
                }

                if(!isSelected()) {
                    $('.c3-chart-legend.all li a').each(function(){
                        if($(this).next().text() !== ''){
                            $(this).addClass('run');
                        }
                    });
                }

                domtoimage.toBlob( document.getElementById('main-container'), { quality: 1 , filter: filter })
                    .then(function (blob) {
                        sendImage(blob);
                        //window.saveAs(blob, 'my-node');
                    });

                e.preventDefault();
            });


            $('.pdf-generator .list').click(function(e){
                $('.pdf-list').toggleClass('active');
                e.preventDefault();
            });

            $('.pdf-list .close, .pdf-list').click(function(e){
                $('.pdf-list').removeClass('active');
                e.preventDefault();
            });

            $('.pdf-list .pdf-list-inner').click(function(event){
                event.stopPropagation();
            });

        }

        return {
            init: init
        };

    })();

    app.dynamicSlide.init();
    //app.chart.init();
    app.chartNetwork.init();
    app.chartNetworkVip.init();
    app.pharmacies.init();
    app.manageUsers.init();
    app.modal.init();
    app.swipe.init();
    app.autocomplete.init();
    app.showBenefits.init();
    app.generatePDF.init();

})();
